






import Vue from 'vue';
import Component from 'vue-class-component';
import PageTitle from '@/components/PageTitle.vue';

@Component({
  components: {PageTitle},
})
export default class NotFound extends Vue {}
